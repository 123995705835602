import React, { useEffect, useState } from "react";
import "./section-1.css";
import Swiper from "swiper/bundle";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import Tree from "../../../../../assets/logo.png";
import TrustBox from "../../../../trustBox/index";
import { useSelector, useDispatch } from "react-redux";
import { getTotalGiveAway } from "../../../../../actions/landingPageActions";
import { subscribeForNewsLetter } from "../../../../../actions/landingPageActions";
import TrustpilotRating from "../TrustpilotRating";
import { getReviews } from "../../../../../actions/competitions/singleCompetitions";

const Section1 = () => {
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { loading, giveaway } = state.common;
  const isAuthenticated = state.user.isAuthenticated;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const { reviews } = state.competitions;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      async function fetchData() {
        await dispatch(getReviews());
        var swiper = new Swiper(".third-slider", {
          slidesPerView: 1,
          centeredSlides: true, 
          spaceBetween: 20,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      }
      fetchData();
    }
  }, [isMobile, dispatch]);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    if (reviews.count > 0) {
      const total = 5 * reviews.count;
      const sumReviews = reviews.results.reduce(
        (sum, review) => sum + review.rating,
        0
      );
      const getStars = 5 * sumReviews;
      const percentageValue = getStars / total;

      setPercentage(percentageValue);
      // console.log("Percentage:", percentageValue.toFixed(1),"%");
    }
  }, [reviews]);

  return (
    <section className="home-section1">
      <div>
        <h1 className="h1">{t("How it works")}</h1>
        <p className="my-4">
          <span style={{ paddingRight: "2rem", fontSize: "2.3rem" }}>
            {t("Step 1")}
          </span>
          {t("Pick an amazing prize and enter the competition.")}
        </p>
        <p className="my-4">
          <span style={{ fontSize: "2.3rem" }}>{t("Step 2")}</span>{" "}
          {t(
            "Select your tickets, or let the Lucky Dip selector choose a letter and number for you."
          )}
        </p>
        <p className="my-4">
          <span style={{ fontSize: "2.3rem" }}>{t("Step 3")}</span>{" "}
          {t(
            "Finalize payment. Wait for the Live Draw and be lucky... Join our winners podium ! Daily winners !"
          )}
        </p>
        {!loading &&
          giveaway.total_winners > 0 &&
          giveaway.total_giveaway > 15000 && (
            <p className="mt-5 last mt-lg-0">
              {t("Paradise Competitions has already made")} &nbsp;
              <span className="number" style={{ fontSize: "2.7rem" }}>
                {giveaway.total_winners}
              </span>
              {t("winners and given away")}
              <span className="number" style={{ fontSize: "2.7rem" }}>
                {" "}
                &#8364; {giveaway.total_giveaway} !
              </span>
            </p>
          )}
        <div className="row sub-section py-4 mx-auto">
          <div className="col-lg-3 p-0 col-7 col-sm-3 mx-auto m-lg-0 text-center">
            <figure>
              <img className="img-fluid" src={Tree} alt="" />
            </figure>
          </div>
          <div className="col-lg-9 col-12 col-sm-9 ">
            <h4 style={{ fontSize: "2.7rem", marginBottom: "10px" }}>
              {t("You've got to be in it to win it!")}
            </h4>
            <div>
              {isMobile && (
                <div className="col">
                  <div
                    className="third third-slider swiper"
                    style={{ width: "100%", position: "relative" }}
                  >
                    <div
                      className="swiper-wrapper"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {reviews?.results?.map((review) => (
                        <div key={review.id} className="swiper-slide third clients">
                          <a
                            href="https://www.trustpilot.com/review/paradisecompetitions.ro?utm_medium=trustbox&utm_source=TrustBoxReviewCollector"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="stars pb-3">
                              <TrustpilotRating rating={review.rating} />
                            </div>
                            <h2
                              style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                color: "#333",
                                marginTop: "10px",
                                marginBottom: "5px",
                              }}
                            >
                              {review.title}
                            </h2>
                            <p style={{ fontSize: "14px", color: "#666" }}>
                              {review.review_text}
                            </p>
                            <div className="customer">{review.customer}</div>
                          </a>
                        </div>
                      ))}
                    </div>
                    <div className="swiper-button-next swiper-button-white"></div>
                    <div className="swiper-button-prev swiper-button-white"></div>
                  </div>
                  <div
                    className="col"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="stars pb-3 pt-3">
                      <TrustpilotRating rating={percentage} />
                    </div>
                    <p style={{ fontSize: "10px" }}>
                      <p style={{ fontSize: "14px" }}>
                        Based on {reviews.count} reviews
                      </p>
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className={isMobile ? "" : "py-5 py-sm-3"}>
              <TrustBox />
            </div>
            <div className="row p-4">
              <div className="p-0 col-12 btn-group">
                {!isMobile && (
                  <div
                    class="fb-like"
                    data-href="https://web.facebook.com/paradisecompetitions.ro"
                    data-width="20"
                    data-layout="button_count"
                    data-action="recommend"
                    data-size="large"
                    data-share="false"
                    data-colorscheme="dark"
                  ></div>
                )}

                <button className="comunity">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/paradisecompetitions.ro"
                  >
                    <i className="fas fa-gift"></i>{" "}
                    <span style={{ color: "white" }}>Join our community</span>
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
