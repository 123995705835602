import React, { useEffect, useState } from "react";
import moment from "moment";
import "../AffiliateIncome.css";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getPayoutTransactions } from "../../../../../../actions/user/profileActions";

export const PayoutTransactions = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const state = useSelector((state) => state);
  const [currentPage, setCurrentPage] = useState(1);
  const loading = state.user.loading;
  const payoutTransactions = state.user.payoutTransactions;

  useEffect(() => {
    dispatch(getPayoutTransactions(currentPage));
  }, [currentPage]);

  const pageSize = 20;
  const totalCount = payoutTransactions?.count || 0;
  const totalPages = Math.ceil(totalCount / pageSize);

  const renderPageNumbers = () => {
    let pageButtons = [];
  
    pageButtons.push(
      <li key={1} className={`page-item ${currentPage === 1 ? "active" : ""}`}>
        <button className="page-link" onClick={() => setCurrentPage(1)}>
          1
        </button>
      </li>
    );
  
    if (currentPage > 4) {
      pageButtons.push(
        <li key="start-ellipsis" className="page-item disabled" style={{color:"white",fontWeight:700,fontSize:"2rem"}}>
           ...
        </li>
      );
    }
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);
  
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <li key={i} className={`page-item ${i === currentPage ? "active" : ""}`}>
          <button className="page-link" onClick={() => setCurrentPage(i)}>
            {i}
          </button>
        </li>
      );
    }
    if (currentPage < totalPages - 2) {
      pageButtons.push(
        <li key="end-ellipsis" className="page-item disabled" style={{color:"white",fontWeight:700,fontSize:"2rem"}}>
          ...
        </li>
      );
    }
  
    if (totalPages > 1) {
      pageButtons.push(
        <li key={totalPages} className={`page-item ${currentPage === totalPages ? "active" : ""}`}>
          <button className="page-link" onClick={() => setCurrentPage(totalPages)}>
            {totalPages}
          </button>
        </li>
      );
    }
  
    return pageButtons;
  };

  return (
    <section className="transection-section">
      <div className="transection-table">
        <div className="overflower-video1">
          {/* TRANSECTION-DRETAIL-START */}
          {loading ? (
            <Loader
              style={{ display: "inline-block" }}
              type="ThreeDots"
              color="white"
              height={5}
              width={30}
            />
          ) : (
            <div className="row transection-head1 transection-row1 m-0">
              <div className="col">
                <h1>{t("Wallet History")}</h1>
              </div>
              <div className="col amount">
                <h1>{t("Amount")}</h1>
              </div>
              <div className="col">
                <h1>{t("Type")}</h1>
              </div>
            </div>
          )}

          {!loading &&
            payoutTransactions?.results &&
            payoutTransactions?.results?.map((item, i) => (
              <div key={item.id} className="row transection-detail1  m-0">
                <div className="col">
                  <h1>{moment(item.created_at).format("ll")}</h1>
                </div>
                <div className="col amount">
                  <h1>
                    {item.is_credit ? "+" : "-"} {item.amount}
                  </h1>
                </div>
                <div className="col">
                  {item.is_site_cash ? (
                    <h1>Site Credit</h1>
                  ) : (
                    <h1>{item.is_site_cash}Earnings</h1>
                  )}
                </div>
              </div>
            )
            )}
        </div>
      </div>
      
      {payoutTransactions?.count > 19 &&
      (<div class="d-flex justify-content-start mt-4">
        <nav aria-label="Page navigation">
          <ul class="pagination">
            <li
              class={`page-item ${
                payoutTransactions?.previous === null ? "disabled" : ""
              }`}
            >
              <button
                disabled={payoutTransactions?.previous === null}
                onClick={() => setCurrentPage(currentPage - 1)}
                class="page-link"
                aria-label="Previous"
              >
                Prev
              </button>
            </li>

            {renderPageNumbers()}

            <li
              class={`page-item ${
                payoutTransactions?.next === null ? "disabled" : ""
              }`}
            >
              <button
                disabled={payoutTransactions?.next === null}
                onClick={() => {
                  setCurrentPage((prevPage) => prevPage + 1);
                }}
                class="page-link"
                aria-label="Next"
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>)}
    </section>
  );
};
